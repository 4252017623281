import styled from "styled-components"
import { Section } from '../../globalStyles'
import { motion } from "framer-motion";

export const NavbarSection = styled(motion.section)`
    width: 100%;
    top: 0;
    position: ${({ position }) => (position ? position : 'fixed')};
    display: flex;
    z-index: ${({ zindex }) => (zindex ? zindex : '0')};
    background: ${({ background }) => (background ? background : '')};
    flex-direction: ${({ direction }) => (direction ? direction : 'column')};
    opacity: ${({ opacity }) => (opacity ? opacity : '1')};
    justify-content: ${({ justify }) => (justify ? justify : 'center')};
    align-items: ${({ align_items }) => (align_items ? align_items : '')};
    width: ${({ width }) => (width ? width : '')};
    height: ${({ height }) => (height ? height : '')};
    margin: ${({ margin }) => (margin ? margin : '')};
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
`;

export const NavbarItem = styled.span`
    padding: 0 1.3rem;

    @media screen and ( max-width: 1024px ) {
        padding: 0.5rem;
    }

    @media screen and ( max-width:1024px ) {
        display:none;
    }
`;

export const NavbarText = styled.p`
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 1.3rem;
    font-weight: bold;
    text-shadow: 1px 1px black;

    @media screen and (min-width:768px){
        font-size: 1.3rem;
    }

    @media screen and (min-width: 1024px){
        font-size: 1.1rem;
    }
    &:hover {
        color: orange;
    }
`;

export const NavbarContainer = styled.nav`
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    background: none;
`;

export const NavbarLogo = styled.a`
    z-index: 20;
    padding: 0;
    cursor: pointer;

    &:hover{
        transform: rotate(1deg) scale(105%);
    }

    @media screen and (max-width: 1024px) {
        left: 10vw;
    }

    @media screen and (max-width: 550px) {
        left: auto;
        position: static;
        justify-content: center;
        top: 8rem;
    }
`;

export const NavbarIcon = styled.img`
    max-width: 200px;

    @media screen and (max-width: 1024px) {
        max-width: 150px;
    }
`;

export const MobileIcon = styled.div`
    display: none;
    max-width: 160px;

    @media screen and (max-width: 1024px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 2.8rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    list-style: none;
    width: 100%;

    @media screen and (max-width: 1024px) {
        width: 100%;
        height: 100vh;
        flex-direction: column;
        position: fixed;
        align-items: flex-end;
        padding-top: 200%;
        top: 0;
        left: 0;
        opacity: ${({ show }) => (show ? 1 : 0)};
        visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
        transform: translateY(${({ show }) => (show ? '0' : '-10px')});
        transition: opacity 0.5 ease;
        background: transparent;
        
    }

    @media screen and (max-width: 400px){
{/*margin-top: 60px;*/}
    }    

    > li:first-child {
{/*margin-top: 60px;*/}
    }
`;

export const NavMenuListItem = styled.li` 
    text-decoration: none;
    list-style: none;
    margin-bottom:5px;
    
`;

