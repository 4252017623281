import React from 'react'
import Hero from '../components/Hero/Hero'
import Contents from '../components/Contents/Contents'
import FeatureBox from '../components/FeatureBox/FeatureBox'
import FeatureGrid from '../components/FeatureBox/FeatureGrid'

/**
 *    +===========+
 *    | ~Etusivu~ |
 *    +===========+
 * 
 *    Sivuston etusivu. Kehitteillä oleva ominaisuus 'FeatureGrid' kommentoitu 
 *    toistaiseksi pois. 
 * 
 */

const Home = () => {

    return (
        <>
            <Hero heroHeight={'100vh'} heroDataParameter={'home'} mb={'35vh'} />
            <FeatureBox />
            {/* <FeatureGrid /> */}
            <Contents />
        </>
    )
}

export default Home
