import React, { useEffect, useState } from 'react';
import { ModalContainer, EnlargedImage, CloseButton, RightArrow, LeftArrow } from './ImageModalStyles.js'

const ImageModal = ({ is_open, onClose, imageIndex, images }) => {
  const [newIndex, setNewIndex] = useState(imageIndex);
  const [imgSrc, setImgSrc] = useState(images[imageIndex]);

  useEffect(() => {
    setImgSrc(images[newIndex]);
    console.log("IMG SRC imageModal: " + imgSrc)
  }, [is_open, newIndex]);

  useEffect(() => {
    setNewIndex(imageIndex);
  }, [onClose]);

  const handleLeftClick = () => {
    setNewIndex(newIndex > 0 ? newIndex - 1 : images.length - 1);
  }

  const handleRightClick = () => {
    setNewIndex(newIndex < images.length - 1 ? newIndex + 1 : 0)
  }

  return (
    <ModalContainer is_open={is_open}>
      <LeftArrow onClick={handleLeftClick} color='#d90' />
      <EnlargedImage src={imgSrc} alt="Enlarged" />
      <CloseButton onClick={onClose}> Close </CloseButton>
      <RightArrow onClick={handleRightClick} color='#d90' />
    </ModalContainer>
  );
};

export default ImageModal;
