export const navData = [
    {
        text: "Etusivu",
        link: "/"
    },
    {
        text: "Maanrakennustyöt",
        link: "/maanrakennustyot"
    },
    {
        text: "Kalusto",
        link: "/kalusto"
    },
    {
        text: "Yhteystiedot",
        link: "/yhteystiedot"
    },
    {
        text: "Verkkokauppa",
        link: "https://www.maa-aines.com/"
    }
];
