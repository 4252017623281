export const TietosuojaData = [
    {
        title: 'Rekisterinpitäjä',
        text: ['Kuljetus- ja Maansiirtoliike K. Timonen Oy', 'Y-tunnus 0883767-3', 'Vieterikatu 12 15700 LAHTI', 'kimmo.timonen@maanrakennustimonen.fi', 'Puh. 040 540 0334'],
        items: []
    },
    {
        title: 'Rekisteriasioita hoitava henkilö',
        text: ['Kimmo Timonen', 'kimmo.timonen@maanrakennustimonen.fi', 'Puh. 040 540 0334'],
        items: []
    },
    {
        title: 'Tietosuojavastaava',
        text: ['Kimmo Timonen', 'kimmo.timonen@maanrakennustimonen.fi', 'Puh. 040 540 0334'],
        items: []
    },
    {
        title: 'Rekisterin käyttötarkoitus',
        text: ['Kerättäviä henkilötietoja käytetään:'],
        items: ['asiakkaan tunnustautumiseen ja mahdollisten käyttöoikeuksien hallintaan', 'rekisteröityneiden käyttäjien tilausten toimittamiseen', 'asiakassuhteen ylläpitoon sekä kehittämiseen']
    },
    {
        title: 'Tietojen keräämisen ja käsittelyn peruste',
        text: ['Asiakkaan tietoja kerätään ja käsitellään asiakkaan suostumuksella tai asiakkaan kanssa tehtävän sopimuksen täytäntöön panemiseksi.'],
        items: []
    },
    {
        title: 'Rekisterin tietosisältö',
        text: ['Keräämme nimi-, puhelin-, sähköposti-, osoite- ja muita jättämiäsi tietoja. Evästeiden avulla saamme vierailijoistamme mitattavia tietoja, joita hyödynnämme esimerkiksi suunnitellessamme markkinointiamme. Evästeiden avulla kerättyä tietoa voidaan käyttää myös kohdennetun markkinoinnin toteuttamiseen (remarketing). Emme luovuta tietojasi kolmansien osapuolten markkinointikäyttöön.'],
        items: []
    },
    {
        title: 'Tietojen säilytysaika',
        text: ['Henkilötiedot säilytetään niin kauan, kun niitä tarvitaan asiakkaan kanssa tehtävän sopimuksen täytäntöön panemiseksi tai asiakaspalvelun kehittämiseksi.'],
        items: []
    },
    {
        title: 'Säännönmukaiset tietolähteet',
        text: ['Rekisteriin kerätään tietoja:'],
        items: ['henkilöltä itseltään', 'viranomaisen pitämistä rekistereistä lain sallimissa rajoissa', 'tietoja kerätään myös Google Analytics –analytiikkatyökalun avulla']
    },
    {
        title: 'Säännönmukaiset tietojen luovutukset ja tietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle',
        text: ['Tietoja ei säännönmukaisesti luovuteta yrityksen ulkopuolelle. Osa yrityksen käyttämistä ulkopuolisista palvelun- tai ohjelmistontarjoajista saattavat säilyttää tietoja EU:n tai Euroopan talousalueen ulkopuolella.'],
        items: []
    },
    {
        title: 'Evästeiden käyttö',
        text: ['Käytämme sivuillamme ns. cookie-toimintoa eli evästeitä. Eväste on pieni, käyttäjän tietokoneelle lähetettävä ja siellä säilytettävä tekstitiedosto, joka mahdollistaa sivujen ylläpitäjän tunnistamaan usein sivuilla vierailevat kävijät, helpottamaan kävijöiden kirjautumista sivuille sekä mahdollistamaan yhdistelmätiedon laatimisen kävijöistä. Tämän palautteen avulla pystymme jatkuvasti parantamaan sivujemme sisältöä. Evästeet eivät vahingoita käyttäjien tietokoneita tai tiedostoja. Käytämme niitä siten, että voimme tarjota asiakkaillemme kunkin yksilöityjen tarpeiden mukaisia tietoja ja palveluita.', 'Mikäli sivuillamme vieraileva käyttäjä ei halua meidän saavan edellä mainittuja tietoja evästeiden avulla, useimmat selainohjelmat mahdollistavat evästetoiminnon poiskytkemisen. Tämä tapahtuu yleensä selaimen asetuksien kautta. On kuitenkin hyvä ottaa huomioon se, että evästeet voivat olla tarpeellisia joidenkin ylläpitämiemme sivujen ja tarjoamiemme palveluiden asianmukaiselle toimimiselle.'],
        items: []
    },
    {
        title: 'Rekisterin suojaus',
        text: [],
        items: ['tiedot siirretään SSL-suojatun yhteyden ylitse', 'sähköiset tiedot on suojattu palomuurilla, käyttäjätunnuksin ja salasanoin', 'tietojen käyttöoikeus on vain niillä rekisterinpitäjän palveluksessa olevilla henkilöillä, jotka tarvitsevat tietoja tehtävissään']
    },
    {
        title: 'Automaattinen päätöksenteko',
        text: ['Automatisoituja yksittäispäätöksiä (EU:n tietosuoja-asetuksen artikla 22) ei tehdä.'],
        items: []
    },
    {
        title: 'Rekisteröidyn oikeudet',
        text: [],
        items: ['rekisteröidyllä on oikeus tarkastaa, mitä häntä koskevia tietoja henkilötietorekisteriin on talletettu. Kirjallinen tarkastuspyyntö tulee lähettää allekirjoitettuna rekisteriasioista vastaavalle henkilölle', 'tarkastusoikeus on maksuton enintään kerran vuodessa toteutettuna', 'rekisteröidyllä on oikeus vaatia virheellisten tai vanhentuneiden tietojen oikaisua tai poistamista tai tietojen siirtoa järjestelmästä toiseen. Hänellä on myös oikeus rajoittaa tai vastustaa tietojensa käsittelyä EU:n tietosuoja-asetuksen artiklojen 18 ja 21 mukaisesti', 'rekisteröidyllä on oikeus peruuttaa aiemmin antamansa suostumus tietojen käsittelylle tai tehdä henkilötietojensa käsittelyyn liittyvistä seikoista valitus valvontaviranomaiselle', 'rekisteröidyllä on myös oikeus kieltää tietojensa käyttö suoramarkkinointitarkoituksiin']
    },
]