import React from 'react'
import { ContentContainer, 
            ContentItem, 
            ContentTextWrapper 
        } from '../components/Contents/ContentStyles'
import { TietosuojaData } from '../data/TietosuojaData'
import { ListItem, OrderedList, UnorderedList } from '../globalStyles'

/**
 *    +=====================+
 *    | ~Tietosuojaseloste~ |
 *    +=====================+
 * 
 *    Tietosuojaseloste -- tehty olemassa olevan selosteen pohjalta
 *    varmuuden vuoksi. 
 */

const Tietosuojaseloste = () => {
  return (
    <ContentContainer >
        <OrderedList width={'75vw'} margin={'25px 0 0 0'} padding={'50px'}>
            { TietosuojaData.map((el, index) => (
                <ListItem key={ index } list_style={'number'}>
                    <ContentItem key={ index } margin={'0 0 10px 0'}>
                        <ContentTextWrapper color='black'><strong>{ el.title }</strong></ContentTextWrapper>
                            { el.text.map(( txt, i) => (
                                <div key={i}>
                                    <ContentTextWrapper color='black' margin={'0 0 10px 0'}>
                                        { txt }
                                    </ContentTextWrapper>
                                </div>
                                ))}
                            <UnorderedList>
                                { el.items ? 
                                 el.items.map(( itm, j ) => (
                                    <ListItem key={ j } >
                                        <ContentTextWrapper color='black' display={'list-item'}>
                                            { itm }
                                        </ContentTextWrapper>
                                    </ListItem>
                                ))
                                : ''}
                            </UnorderedList>
                    </ContentItem >
                </ListItem>
            ))}
        </OrderedList>
        
    </ContentContainer>
  )
}

export default Tietosuojaseloste