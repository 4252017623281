import React, { useEffect } from 'react'
import { useState } from 'react'
import { HeroContainer, HeroImage, HeroSection, HeroHeading, HeroText, HeroBox, HeroImageContainer } from './HeroStyles'
import { Heading, Button } from '../../globalStyles'
import { HeroData } from '../../data/HeroData'
import { Link } from 'react-router-dom'

/**   +====================+
 *    | ~Hero-komponentti~ |
 *    +====================+
 * 
 *    Hero-bannerin luomiseen komponentti. Hero-osio
 *    tyylitellään eri tavoin sivusta riippuen. 
 * 
 */

const Hero = (props) => {
  const [heroData, setHeroData] = useState([])

  useEffect(() => {
    switch (props.heroDataParameter) {
      case "home":
        setHeroData(HeroData[0])
        break;
      case "maanrakennustyöt":
        setHeroData(HeroData[1])
        break;
      case "kalusto":
        setHeroData(HeroData[2])
        break;
      case "yhteystiedot":
        setHeroData(HeroData[3])
        break;
    }
  }, [])

  return (
    <HeroSection mb={props.mb}>
      <HeroImageContainer height={props.heroHeight}>
        <HeroImage src={heroData.image} />
        <HeroBox />
      </HeroImageContainer>
      {heroData.subTitle ?
        <HeroContainer>
          <HeroHeading mb={'2vh'}>{heroData.subTitle}</HeroHeading>
          <HeroText>{heroData.text}</HeroText>
          <Link to='/yhteystiedot'>
            {heroData.button ?
              <Button padding={'10px 20px'}
                fontSize={'0.8rem'}
                mt={'3rem'}
              >
                {heroData.button}
              </Button> : ""}
          </Link>
        </HeroContainer>
        : ""}
    </HeroSection>
  )
}

export default Hero
