import React from 'react'
import { TopArrow, TopButtonButton } from './TopButtonStyles'

/**
 *    +=============+
 *    | ~TopButton~ |
 *    +=============+
 * 
 *    Nappi, jolla mennään sivun alkuun. 
 * 
 */

const TopButton = () => {

    const scrollToTop = () => {
      window.scrollTo({
          top:0,
          behavior: 'smooth'
      })
    }
    
    return (
        <TopButtonButton onClick={ scrollToTop }>
            <TopArrow /> 
        </TopButtonButton>
    )
}

export default TopButton