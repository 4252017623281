import styled from "styled-components";
import { Image } from '../../globalStyles'

export const SliderContainer = styled.div`
    margin: 0 25px;
    width: 50vw;
    height: auto;
    vertical-align: center;
    z-index: 5;
    border-radius: 5vh;
    box-shadow: 5px 5px 5px 5px #666;

    :hover {
        border-radius: 5vh;
        box-shadow: 0 0 10px 10px #eee;
    }

    @media screen and (max-width:1280px) {
        width: 80vw;
    }

    @media screen and (max-width:768px) {
        width: 90vw;
    }
`;

export const CarouselFocuser = styled.span`
    height: ${({ height }) => (height ? height : 'auto')};
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
`;

export const CarouselImage = styled(Image)`
    object-fit: ${({ object_fit }) => (object_fit ? object_fit : 'cover')};
    border-radius: ${({ border_radius }) => (border_radius ? border_radius : '5vh')};
    height: ${({ height }) => (height ? height : 'auto')};
    width: 100%;
    min-height: ${({ min_height }) => (min_height ? min_height : 'auto')};
    filter: ${({ filter }) => (filter ? filter : 'none')};
`;

export const CarouselArrow = styled.div`
    height: 3vh;
    width: 3vh;
    border-bottom: 1.1vh solid #d90;
    border-left: 1.1vh solid #d90;
    border-right: none;
    border-top: none;
    position: relative;
    top: 50%;
    z-index: 6;
    cursor:pointer;
    background: transparent;
`;

export const CarouselArrowLeft = styled(CarouselArrow)`
    transform: rotate(45deg);
`;

export const CarouselArrowRight = styled(CarouselArrow)`
    transform: rotate(225deg);
`;
