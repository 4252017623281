export const FooterData = [
    {
        name: "Osoite",
        text: "Vieterikatu 12 15700 LAHTI",
        link: "https://www.google.fi/maps/place/Kuljetus-+ja+Maansiirtoliike+K.+Timonen+Oy/@60.9432208,25.5940239,15.58z/data=!4m6!3m5!1s0x468e27d4232d32bb:0x60ec1f6be6763e47!8m2!3d60.943229!4d25.6059408!16s%2Fg%2F11fmwvrl8z?entry=ttu"
    },
    {
        name: "Puhelin",
        text: "040 540 0334",
        link: "tel:+358405400334"
    },
    {
        name: "Sähköposti",
        text: "info@maanrakennustimonen.fi",
        link: "mailto:info@maanrakennustimonen.fi"
    },
    {
        name: "Kotisivu",
        text: "maanrakennustimonen.fi",
        link: "https://maanrakennustimonen.fi"
    }
]