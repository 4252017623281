import React, { useState, useEffect } from 'react'
import { FormContainer, FormInput, FormRow, TextArea, ToastContainerBox } from './FormStyles'
import { Button } from '../../globalStyles'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import axios from 'axios'


/**********************************************************|
 ***************|===========================|**************|
~***************| ~~~~Form-komponentti~~~~~ |**************|
 ***************|===========================|**************|
 *
 * Yhteydenotto-formin käsittelyyn komponentti. 
 * 
 * Lomake käyttää Elisan yrityswebin tarjoamaa 
 * 'palautelomake'- toiminnallisuutta hyödykseen. 
 * 
 * Ohjeet: https://yrityksille.elisa.fi/ohje/yritysweb-palautelomake 
 * 
 * Asiakaas alusi, että viestin lähetettyään sivu pysyy 
 * samana, mutta Elisan palvelu pakottaa asettamaan jonkin 
 * sivun, jolle palataan viestin lähettämisen jälkeen. 
 * 
 * handleClick- funktio käyttää react-toastify- kirjastoa 
 * onnistumis- tai virhesanoma- viesti-ikkunan luomiseen, 
 * mutta toiminto on poissa käytöstä edellä mainitun 
 * Elisan Yritysweb- palvelun toiminnallisuuden takia. 
 * 
 * Vaatii lisäselvittelyä. 
 * 
 */

const Form = () => {
    const [ formData, setFormData ] = useState({
        name:'',
        company: '',
        phone: '',
        email: '',
        message: '',
    });

    const formId = "formContainer";

    const handleChange = (e) => {

        const { name, value } = e.target;
        setFormData( values => ({...values, [name]: value}));

        console.log(formData.name, formData.email)
    }

    const handleClick =  (e) => {
        e.preventDefault();
        toast.success("Kiitos yhteydenotosta, " +  formData.name );
        document.getElementById(formId).reset();
    }

    return (
        <FormContainer id={ formId }  action='/cgi-bin/postita' method='POST'>

            <input type="hidden" name="EMAIL"       value="info@maanrakennustimonen.fi"/>
            <input type="hidden" name="SUBJECT"     value="Viesti www-sivulta"/>
            <input type="hidden" name="RETURNPAGE"  value="http://www.maanrakennustimonen.fi.testwww.yritysweb.fi/"/>
            <input type="hidden" name="NOEMPTY"     value="Nimi Sähköpostiosoite"/>
            <input type="hidden" name="NOEMPTYPAGE" value="/"/>
            <input type="hidden" name="LANG"        value="FIN"/>

            <FormInput name='Nimi'        id='name'     placeholder='Nimi:'       type='text' onChange={ handleChange }/>
            <FormInput name='Yritys'      id='company'  placeholder='Yritys:'     type='text' onChange={ handleChange }/>
            <FormInput name='Puhelin'     id='phone'    placeholder='Puhelin:'    type='text' onChange={ handleChange }/>
            <FormInput name='Sähköposti'  id='e_mail'   placeholder='Sähköposti:' type='text' onChange={ handleChange }/>
            <TextArea name='Viesti'       id='message'  placeholder='Viesti:'     type='text' onChange={ handleChange } height={'80px'} ></TextArea>
            <Button width={'150px'} 
                    mt={'3rem'} 
                    type='submit' 
                    //onClick={ handleClick } 
                    >
                        Lähetä
                    </Button>
            <ToastContainer position={'bottom-center'} 
                            theme={'dark'}
                            autoClose={'5000'} 
                            closeOnClick={'true'} 
                            newestOnTop
            />
        </FormContainer>
  )
}

export default Form