import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Maanrakennustyot from './pages/Maanrakennustyot';
import Kalusto from './pages/Kalusto';
import Yhteystiedot from './pages/Yhteystiedot';
import Navbar from './components/Navbar/Navbar';
import GlobalStyles from './globalStyles';
import Footer from './components/Footer/Footer';
import Tietosuojaseloste from './pages/Tietosuojaseloste';

function App() {
    return (
        <Router>
            <GlobalStyles />
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/maanrakennustyot" exact Component={Maanrakennustyot} />
                <Route path="/kalusto" exact Component={Kalusto} />
                <Route path="/yhteystiedot" exact Component={Yhteystiedot} />
                <Route path="/tietosuojaseloste" exact Component={Tietosuojaseloste} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
