export const FeatureBoxData = [
    {
        title: 'Maanrakennustyöt',
        text: 'Tarjoamme monipuoliset maanrakennus-, maansiirto-ja kuljetuspalvelut kaikenkokoisille asiakkaille. Meidät tunnetaan kokeneen toimijan luotettavuudesta sekä laadusta, jota ei tarvitse käydä jälkeenpäin korjailemassa.',
        color: '#ffc828',
        link: '/maanrakennustyot',
        buttonText: 'Lue lisää palveluistamme'
    },
    {
        title: 'Kalusto',
        text: 'Kunnon kalustolla saadaan aikaan kunnon jälkeä! Yrityksellämme on laaja oma konekanta, jonka turvin pystymme sujuvasti tekemään niin pienet kuin suuretkin urakatja toimittamaan isommatkin maa-aineskuljetukset.',
        color: '#d90',
        link: '/kalusto',
        buttonText: 'Lue lisää kalustostamme'
    },
    {
        title: 'Verkkokauppa',
        text: 'Tilaa tarvitsemasi maa-aines kätevästi suoraan kohteeseen kuljetettuna! Teemme yhteistyötä Maa-aines.comin kanssa, jonka verkkokaupasta pääset tekemään tilauksen 24/7. Toimitamme tilauksesi perille haluamaasi aikaan.',
        color: '#ffc828',
        link: "https://www.maa-aines.com/",
        buttonText: 'Tästä verkkokauppaan'
    },
]

export const FeatureGridData = [
    {
        title: 'Paalutus',
        image: './assets/naama.png',

    },
    {
        title: 'Vitutus',
        image: './assets/naama.png',

    },
    {
        title: 'Ajan hiekkaa vain',
        image: './assets/naama.png',

    },
    {
        title: 'Nälkä',
        image: './assets/naama.png',

    },
    {
        title: 'Panetus',
        image: './assets/naama.png',

    },
    {
        title: 'Paalutus taas',
        image: './assets/naama.png',

    },
]