import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const addHtml = ( data ) => {
    return <div dangerouslySetInnerHTML={{ __html: data }}/>
}

export const useScrollToTop = () => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo({ top: 0 });
      // scroll to the top of the browser window when changing route
      // the window object is a normal DOM object and is safe to use in React.
    }, [location]);
};
