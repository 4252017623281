import React, { useEffect } from 'react'
import { Section } from '../../globalStyles'
import {
  ContentBoxContainer,
  ContentColumn,
  ContentContainer,
  ContentRow,
  ContentSubHeading,
  ContentTextWrapper,
  ContentItem,
  ContentListItem,
  ContentImage,
  ContentList,
} from './ContentStyles'
import { ContentsYhteystiedotData as cyd, myynti } from '../../data/ContentsData'
import Form from '../Form/Form'
import { addHtml } from '../Functions'
import { useLocation } from 'react-router-dom'

const ContentsYhteystiedot = () => {

  console.log("MYYNTI: " + myynti[0].name)
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  return (
    <ContentContainer >
      <Section width={'100%'}
        margin={'10px 0'}
        zindex={'0'}
        height={'100%'}
        align_items={'center'}
      >
        <ContentBoxContainer align={'center'}
          background={'#eee'}
        >
          <ContentRow width={'100%'}
            mt={'10px'}
            padding={'1rem 30px'}
          >
            <ContentColumn>
              <ContentRow>
                <ContentColumn>
                  <ContentSubHeading >{cyd[0].title}</ContentSubHeading>
                  <ContentItem margin={'0 25px 0 8px'}>
                    <ContentListItem >
                      <ContentTextWrapper>{addHtml(cyd[0].text[0])}</ContentTextWrapper>
                    </ContentListItem>
                    <ContentListItem >
                      <ContentTextWrapper>{addHtml(cyd[0].text[1])}</ContentTextWrapper>
                    </ContentListItem>
                  </ContentItem>
                </ContentColumn>
                <ContentItem>
                  <ContentImage src={cyd[0].source} />
                </ContentItem>
              </ContentRow>
            </ContentColumn>
          </ContentRow>
        </ContentBoxContainer>
      </Section>
      <Section width={'100%'}
        margin={'10px 0'}
        zindex={'0'}
        height={'100%'}
        align_items={'center'}
      >
        <ContentBoxContainer align={'center'}
          background={'#eee'}>
          <ContentRow padding={'1rem 30px'} width={'100%'}>
            <ContentColumn>
              <ContentItem align_self={'center'}>
                <ContentSubHeading>Laskutustiedot</ContentSubHeading>
              </ContentItem>
              <ContentRow justify_content='space-around'>
                <ContentItem margin={'25px'} text_align='center'>
                  <ContentSubHeading mb={'20px'} mt={'20px'}>{cyd[1].title}</ContentSubHeading>
                  {cyd[1].text.map((el, index) => (
                    <ContentTextWrapper key={index}>{addHtml(el)}</ContentTextWrapper>
                  ))}
                </ContentItem>
                <ContentItem margin={'25px'} text_align='center'>
                  <ContentSubHeading mb={'20px'} mt={'20px'}>{cyd[2].subTitle}</ContentSubHeading>
                  {cyd[2].text.map((el, i) => (
                    <ContentTextWrapper key={i}>{addHtml(el)}</ContentTextWrapper>
                  ))}
                </ContentItem>
                <ContentItem margin={'25px'} text_align='center'>
                  <ContentSubHeading mb={'20px'} mt={'20px'}>{cyd[3].subTitle}</ContentSubHeading>
                  {cyd[3].text.map((el, i) => (
                    <ContentTextWrapper key={i}>{addHtml(el)}</ContentTextWrapper>
                  ))}
                </ContentItem>
              </ContentRow>
            </ContentColumn>
          </ContentRow>
        </ContentBoxContainer>
      </Section>
      <Section width={'100%'}
        margin={'10px 0'}
        zindex={'0'}
        height={'100%'}
        align_items={'center'}
      >
        <ContentBoxContainer align={'center'}
          background={'#eee'}
        >
          <ContentRow padding={'1rem 30px'} width={'80%'}>
            <ContentColumn>
              <ContentItem align_self='center'>
                <ContentSubHeading color={'black'} >Myynti</ContentSubHeading>
              </ContentItem>
              <ContentColumn flex='0'>
                <ContentRow flex='0' justify_content={'space-around'}>
                  <EmployeeCard el={myynti[0]} />
                </ContentRow>
                <ContentRow flex='0' justify_content={'space-around'}>
                  <EmployeeCard el={myynti[1]} />
                  <EmployeeCard el={myynti[2]} />
                </ContentRow>
                <ContentRow flex='0' justify_content={'space-around'}>
                  <EmployeeCard el={myynti[3]} />
                  <EmployeeCard el={myynti[4]} />
                </ContentRow>
              </ContentColumn>
            </ContentColumn>
          </ContentRow>
        </ContentBoxContainer>
        <ContentBoxContainer align={'center'}
          background={'#eee'}
          mt='10px'>
          <ContentRow padding={'1rem 30px'}>
            <ContentColumn width={'50%'} justify_content={'start'}>
              <ContentItem>
                <ContentSubHeading mb={'20px'} mt={'20px'}>{cyd[4].title}</ContentSubHeading>
                {cyd[4].text.map((el, i) => (
                  <ContentTextWrapper key={i}>{addHtml(el)}</ContentTextWrapper>
                ))}
              </ContentItem>
            </ContentColumn>
            <ContentColumn width={'50%'}>
              <Form />
            </ContentColumn>
          </ContentRow>

        </ContentBoxContainer>
      </Section>

    </ContentContainer>
  )
}

export default ContentsYhteystiedot

const EmployeeCard = ({ el }) => {

  console.log("EL: " + el.name)
  return (
    <ContentItem margin={'25px 0 0 25px'} flex='initial'>
      <ContentTextWrapper>{addHtml('<strong>' + el.name + '</strong>')} </ContentTextWrapper>
      <ContentTextWrapper>{el.title} </ContentTextWrapper>
      <br />
      <ContentTextWrapper>{addHtml('Puh: <a href="tel:' + el.phone + '">' + el.phone + '</a>')} </ContentTextWrapper>
      <ContentTextWrapper>{addHtml('<a href="mailto:' + el.e_mail + '">' + el.e_mail + '</a>')} </ContentTextWrapper>
    </ContentItem>
  )
}
/* Google maps-kartta (tarvii API-avaimen)
<ContentColumn width={ '100%' } height={'400px'}>
                        <ContentItem width={'100%'} height={'100%'} >
                            <ContentIframe src="" "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31009.177117064977!2d25.5779774054546!3d60.93806052222554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468e27d4232d32bb%3A0x60ec1f6be6763e47!2sKuljetus-%20ja%20Maansiirtoliike%20K.%20Timonen%20Oy!5e0!3m2!1sfi!2sfi!4v1702315161486!5m2!1sfi!2sfi" 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade" 
                    />
                </ContentItem>
            </ContentColumn> 
*/
