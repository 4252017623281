import styled from "styled-components";
import { PageContainer, Heading } from "../../globalStyles";

export const HeroSection = styled.section`
    background-position: center;
    background-size: cover;
    display:flex;
    justify-content: center;
    width: 100%;
    top:0;
    z-index: -9;
    left: 0;
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
`;

export const HeroImageContainer = styled.div`
    * { 
    height: ${({ height }) => (height ? height : 'clamp(650px, 100vh, 100vh)')} ;
    object-fit: cover;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed; 
    }
`;

export const HeroImage = styled.img`
    z-index: -9;
`;

export const HeroBox = styled.div`
    box-shadow: inset 0 0 20vw 20vh rgba(34, 34, 34, 0.9), inset 0 0 0 1000px rgba(128, 128, 128, 0.6);
    z-index: -8;
`;
/*
 * Oranssi box-shadow
 * box-shadow: inset 0 0 0 1000px rgba(221, 153, 0, 0.1), inset 0 0 80px 80px rgba(0, 0, 0, 0.8);
*/

export const HeroContainer = styled(PageContainer)`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 45vh;

    @media screen and (max-width: 1024px) {
        width: 90%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 35vh;
    }
`;

export const HeroHeading = styled(Heading)`
    color: #EAF0FF;
    font-size: 2.8rem;
    text-shadow: 1px 1px #222;

    @media screen and (max-width: 480px) {
        font-size: 2.4rem;
    }
`;

export const HeroText = styled.p`
    height: 100%;
    color: #EAF0FF;
    text-align: center;
    text-shadow: 1px 1px #222;
    font-size: 1.4rem;
    font-weight: 600;
`;
