import React from 'react'
import Hero from '../components/Hero/Hero'
import ContentsYhteystiedot from '../components/Contents/ContentsYhteystiedot'

/**
 *    +================+
 *    | ~Yhteystiedot~ |
 *    +================+
 * 
 *    Yrityksen ja sen tärkeimpien henkilöiden yhteystiedot, 
 *    yhteydenottolomake. 
 */

const Yhteystiedot = () => {
    return (
        <>
            <Hero heroHeight={'70vh'} heroDataParameter={"yhteystiedot"} mb={'0'} />
            <ContentsYhteystiedot />
        </>
    )
}

export default Yhteystiedot
