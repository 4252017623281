import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  NavbarSection,
  NavbarContainer,
  NavbarItem,
  NavbarText,
  NavbarLogo,
  NavbarIcon,
  MobileIcon,
  NavMenu,
  NavMenuListItem
} from './NavbarStyles'
import { navData } from '../../data/NavbarData'
import { CgMenuRight } from 'react-icons/cg'
import { FaTimes } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { Row } from '../../globalStyles'
import TopButton from '../TopButton/TopButton'

/**
 *      +====================+
 *      | ~Navigointipalkki~ |
 *      +====================+
 * 
 *      Navigointipalkki-komponentti. Navigointi toteutettu 
 *      react-router-domin useNavigate- hookilla. 
 * 
 *      Sivun leveyden lähennellessä mobiililaitetta, 
 *      vaihtuu navigointipalkki menuvalikoksi. 
 * 
 *      Logoa klikattaessa siirrytään etusivulle. 
 */


const Navbar = () => {

  const [show, setShow] = useState(false)
  const [url, setUrl] = useState("/");
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const navigate = useNavigate()


  const handleScroll = () => {

    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLastScrollY(currentScrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    };
  }, [lastScrollY]);

  const handleClick = (link) => {

    try {
      if (link === undefined) {
        navigate("/")
      } else {
        setUrl(link)
      }

      console.log(url);
      navigate(link, { preventScrollReset: 'false' });

    }
    catch (e) {
      console.log(e)
    }
  }

  const goTop = () => {
    navigate(url, { preventScrollReset: true })
  }

  const handleMenuClick = () => {
    setShow(!show)
  }
  return (
    <IconContext.Provider value={{ color: '#fff' }}>
      <NavbarSection
        initial={{ y: 0 }}
        animate={{ y: isVisible ? 0 : -115 }}
        transition={{ type: 'tween', duration: 0.2, ease: "easeOut" }}
        direction={'row'}
        background={'linear-gradient(rgba(34, 34, 34, 1), rgba(34, 34, 34, 0.35))'}
        max_width={'100vw'}
        height={'115px'}
        align_items={'center'}
        zindex={'99'}
        position={'fixed'}
        isVisible={isVisible}
      >
        <Row margin={'0 10vw'}>
          <NavbarLogo margin={'0 25vw'}>
            <NavbarIcon
              src="./assets/Kuljetus-ja-Maansiirtoliike-K.-Timonen-Oy-logo.png"
              onClick={() => handleClick("/")}
            />
          </NavbarLogo>
          <MobileIcon onClick={handleMenuClick}>
            {show ? <FaTimes /> : <CgMenuRight />}
            <NavMenu show={show}>
              {show ? navData.map((el, index) => {
                return <NavMenuListItem key={index}>
                  <Link to={el.link}>
                    <NavbarText>{el.text}</NavbarText>
                  </Link>
                </NavMenuListItem>
              }) : ''}
            </NavMenu>
          </MobileIcon>
          <NavbarContainer margin={'0'}>
            {navData.map((el, index) => (
              <NavbarItem key={index}>
                <Link to={el.link}>
                  <NavbarText>{el.text}</NavbarText>
                </Link>
              </NavbarItem>
            ))}
          </NavbarContainer>
        </Row>
      </NavbarSection>
      <TopButton onClick={() => goTop()} />
    </IconContext.Provider>
  )
}

export default Navbar
