import styled from "styled-components";
import { Button } from "../../globalStyles";

export const TopButtonButton = styled(Button)`
    /* background: url('./assets/naama.png');  */
    position: fixed;
    bottom: 5%;
    right: 5%; 
    z-index: 10;
    padding: 8px 16px;
`;

export const TopArrow = styled.div`
    border: solid #d90;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px 3px 3px 5px;

    transform: rotate(225deg);
`;