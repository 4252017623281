import styled from "styled-components";
import { Row } from '../../globalStyles'

export const FooterContainer = styled.div`
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.35);
    display: grid;
    align-items: center;
    justify-items: center;
    position: static;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    padding: 0 10vw;
`;

export const FooterLogo = styled.a`
    
    z-index: 10;
    padding: 0;
    cursor: pointer;
    margin-bottom: ${({ mb }) => (mb ? mb : '-30px')};
    margin-top: ${({ mt }) => (mt ? mt : '30px')};

    &:hover{
        transform: rotate(1deg) scale(105%);
    }

    @media screen and (max-width: 1280px) {
        left: 15vw;
    }
`;

export const FooterIcon = styled.img`
    
    max-width: 250px;
    margin-right: 10px;

    @media screen and (max-width: 1024px) {
        max-width: 200px;
    }
`;

export const FooterRow = styled(Row)`
    justify-content: space-around;

    @media screen and (max-width: 1024px) {
        width: 95%;
    }
    @media screen and (max-width: 640px) {
        flex-direction: column;
        justify-content: center;
        width: 50%;
        margin-top:20px;
    }
`;

export const FooterTextWrapper = styled.a`
    color: white;
    text-shadow: 1px 1px #222;
    font-size: clamp(0.6rem, 0.8rem, 1.0rem);
    padding: 2px;
    cursor: pointer;
    text-decoration: none;
    margin: 2px 0;

    ::before {
        white-space: pre;
        content: ' '
    }
`;
