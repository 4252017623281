import styled from "styled-components";
import { motion } from "framer-motion";
import { HThree } from "../../globalStyles";


export const FeatureBoxRow = styled(motion.div)`
    display: flex;
    flex-direction: ${({ inverted }) => (inverted ? 'row-reverse' : 'row')};
    width: ${({ width }) => (width ? width : '100%')};
    margin-top: ${({ mt }) => (mt ? mt : '0')};
    align-items: center; 
    justify-content: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    flex: 1;
    max-width: 80vw;
    background:transparent;


    @media screen and (max-width:1280px){
        max-width: 95vw;
    }

    @media screen and (max-width: 960px) {
        flex-direction: column;
        height: 100%;
    }
`;

export const FeatureBoxColumn = styled.div`
    display: flex;
    flex-wrap: break-word;
    flex: 1; 
    overflow: scroll;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 30px 25px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin: ${({ margin }) => (margin ? margin : '3px 0')};
    height: 300px;
    padding: ${({ padding }) => (padding ? padding : '25px 2vw')};

    cursor: ${({ cursor }) => (cursor ? cursor : 'initial')};

    background-color: ${({ bc }) => (bc ? bc : '')};

    &:hover {
        transform: ${({ hover_scale }) => (hover_scale ? 'scale(105%)' : 'null')};
    }

`;

export const FeatureBoxTextWrapper = styled.p`
    display: ${({ display }) => (display ? display : 'inline')};
    margin: ${({ margin }) => (margin ? margin : '10px 0')};
    padding: 0;
    font-size: ${({ font_size }) => (font_size ? font_size : 'clamp(1.1rem, 2.5vh, 1.3rem)')}; 
    font-weight: ${({ font_weight }) => (font_weight ? font_weight : '700')};
    color: ${({ color }) => (color ? color : 'inherit')};
    line-height: ${({ lh }) => (lh ? lh : '1.8rem')};
    text-align: center;

    @media screen and (max-width: 1600px) {
        line-height: 1.4rem;
    }

    @media screen and (max-width: 960px) {
        line-height: 1.8rem;
    }
`;

export const FeatureGridContainer = styled.div`
    margin: 35px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-gap: 5px;
    border: 5px solid purple;
`;

export const FeatureGridItem = styled.div`
    display: flex;
    flex-direction: column;
    border: 5px solid green;
    justify-content: start;
    padding: 25px;
`;

export const FeatureGridHThree = styled(HThree)`
    font-size: 2rem;
    text-align: center;
    align-self: center;
    font-weight: 700;
`;
