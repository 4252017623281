import React from 'react'
import Hero from '../components/Hero/Hero'
import ContentsMaanrakennus from '../components/Contents/ContentsMaanrakennus'

/**
 *    +=========================+
 *    | ~Maanrakennustyöt-sivu~ |
 *    +=========================+
 * 
 *    Tällä sivulla esitellään tarkemmin yrityksen maanrakennustoimintaa. 
 */

const Maanrakennustyot = () => {
    return (
        <>
            <Hero heroHeight={'70vh'} heroDataParameter={"maanrakennustyöt"} mb={'0'} />
            <ContentsMaanrakennus />
        </>
    )
}

export default Maanrakennustyot
